.FavoriteSelectionBox .ui.selection.dropdown .menu > .item {
    overflow-x: auto;
}

.FavoriteSelectionBox .ui.active.visible.search.selection.dropdown {
    width: 100%;
}

.FavoriteSelectionBox .button-group button {
    width: 100%;
}