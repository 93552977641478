.FavoriteItem {
    display: flex;
    flex-direction: row;
    position: relative;
    min-height: fit-content;
}

.FavoriteItem .container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.FavoriteItem .group-dropdown {
    min-width: 180px;
}

.FavoriteItem .container .actions {
    margin: 0 auto;
}

.FavoriteItem .container .actions i {
    margin: 9px;
    font-size: 1.3em;
    color: black;
}

.FavoriteItem .container .actions i:hover {
    color: var(--gi_color_accent_color);
}

.FavoriteItem .container > div {
    display: flex;
    margin: 5px;
}

.FavoriteItem .container > div:nth-child(2) {
    display: flex;
    flex: 1;
    min-width: 200px;
}

.FavoriteItem .container > div:nth-child(3) {
    display: flex;
    flex: 1;
}

.FavoriteItem {
    margin: 2px;
}

.FavoriteItem.address {
    /*background-color: rgba(14, 114, 181, 0.24);*/
}

.FavoriteItem.address:hover {
    background-color: var(--gi_color_light_transparent_primary_color);
}

.FavoriteItem.region {
    /*background-color: rgba(29, 181, 53, 0.24);*/
}

.FavoriteItem.region:hover {
    background-color: var(--gi_color_light_transparent_primary_color);
}

.FavoriteItem.address.selected,
.FavoriteItem.region.selected {
    background-color: var(--gi_color_accent_transparent_color);
}

.FavoriteItem .fas:not(.disabled) {
    cursor: pointer;
}

.FavoriteItem .fas.fa-map-marker-alt.disabled {
    opacity: 0.2;
}