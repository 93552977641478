@import "../../../../../css/slick-carousel/slick.css";
@import "../../../../../css/slick-carousel/slick-theme.css";

.DetailItem,
.DetailItem div
{
    display: flex;
    flex-direction: column;
}
.DetailItem .heading h3
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
}

.DetailItem .heading{
    padding: 15px;
}

.UsageDetail .DetailItem .heading h2{
    text-align: center;
}

.UsageDetail {
    display: flex;
    width: 100%;
    justify-content: center;
}
.UsageDetail .content{
    max-width: 600px;
    width: 100%;
    flex-direction: column;
}

.UsageDetail .property-fat{
    font-weight: bold;
}

.UsageDetail .company-property{
    flex-direction: row;
}

.UsageDetail .multicategory{
    display: flex;
    flex-direction: column;
}

.UsageDetail .company-property.accordion{
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.UsageDetail .company{
    margin-top: 10px;
}

.DetailItem{
    margin-top: 20px;
}


.DetailItem .empty-selection{
    text-align: center;
}

.DetailItem .company h4{
    margin-bottom: 0px;
}

/*google places component*/
.GooglePlacesDetail .heading{
    padding: 0;
}

.GooglePlacesDetail .point-of-interest {
    margin-top: 10px;
}

.GooglePlacesDetail h4 {
    margin: 0;
}


.GooglePlacesDetail .poi {
    display: flex;
    flex-direction: row;
}

.GooglePlacesDetail .poi-slider,
.GooglePlacesDetail .poi-slider div{
    display: block;
}
.GooglePlacesDetail .poi-slider{
    margin: 20px;
}

.GooglePlacesSlide .poi-slider,
.GooglePlacesSlide .poi-slider div{
    display: block;
}
.GooglePlacesSlide .poi-slider{
    margin: 20px;
}
.GooglePlacesSlide .slider-container{

}
.GooglePlacesDetail .GooglePlacesSlide div{
    display: block;
}

.GooglePlacesSlide .company-property .property-fat{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.UsageDetail .company-property.long-txt{
    display: flex;
    flex: 1;
    flex-direction: column;
}
.UsageDetail .company-property.long-txt .scrollable{
    max-height: 100px;
    overflow-y: scroll;
}

/* prev, next buttons */
#root .GooglePlacesDetail .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    z-index: 1;
}

#root .GooglePlacesDetail .slick-prev{
    -webkit-transform: translate(25px, -50%);
    -ms-transform: translate(25px, -50%);
    transform: translate(25px, -50%);
}
#root .GooglePlacesDetail .slick-next {
    -webkit-transform: translate(-25px, -50%);
    -ms-transform: translate(-25px, -50%);
    transform: translate(-25px, -50%);
}

#root .GooglePlacesDetail .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: var(--gi_color_dark_primary_color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 1;
}

.UsageDetail .EnolystData{
    margin-top: 10px;
}

.UsageDetail .EnolystData .fat{
    margin-top: 5px;
    font-weight: bold;
}

.UsageDetail .EnolystData .person{
    display: flex;
    flex-direction: row;
}

.UsageDetail .EnolystData button{
    background: none;
    display: block;
    cursor: pointer;
    color: var(--gi_color_accent_color);
    display: block;
    width: fit-content;
    width: -moz-fit-content;
    text-align: left;
    padding: 0;
}
.UsageDetail .EnolystData button:hover{
    text-decoration: underline;
    background: none;
    color: var(--gi_color_accent_color);
}

.UsageDetail .EnolystData .powered-by{
    margin-top: 5px;
    /*    font-weight: bold;*/
    /*    color: var(--gi_color_accent_color);*/
}

.UsageDetail .building-usage .fat{
    font-weight: bold;
}
.UsageDetail .building-usage .ownerprediction ul{
    margin-top: 0;
    margin-bottom: 0;
}
.UsageDetail .building-usage .ownerprediction .entry{

}
.UsageDetail .building-usage .ownerprediction .entry span{

}
