.Card.PublicEnergyProject {
    display: flex;
    flex-direction: column;
}

.Card.PublicEnergyProject .context-menu {
    display: flex;
}

.Card.PublicEnergyProject .card-container-absolute {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    min-height: fit-content;
    overflow: auto;
}

.Card.PublicEnergyProject .card-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    min-height: fit-content;
}

.Card.PublicEnergyProject .public-card {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    max-width: 400px;
    width: 100%;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
}

/*CARD TOP BAR START*/
.Card.PublicEnergyProject .context-menu {
    display: flex;
    min-height: 41px;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
}

.Card.PublicEnergyProject .context-menu .menu-items-wrapper {
    display: flex;
    flex-direction: column;
}

.Card.PublicEnergyProject .context-menu .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

/*CARD TOP BAR END*/

.Card.PublicEnergyProject .public-card.focused {
    background-color: rgba(14, 114, 181, 0.3);
}

.Card.PublicEnergyProject .public-card .controls div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Card.PublicEnergyProject .public-card .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 4px;
}

.Card.PublicEnergyProject .public-card .controls i {
    margin: 5px;
    cursor: pointer;
}

.Card.PublicEnergyProject .card-container .controls div:first-child {
    float: left;
    align-items: center;
    padding: 5px;
    width: 100%;
}

.Card.PublicEnergyProject .public-card .image {
    width: 100%;
    min-width: 210px;
    max-width: 600px;
    min-height: 210px;
    max-height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 0 auto;
    display: flex;
}

.Card.PublicEnergyProject .public-card .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Card.PublicEnergyProject {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.Card.PublicEnergyProject:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

/*DETAIL START*/
.Card.PublicEnergyProject .card-container .message {
    margin: 0 auto;
}

.Card.PublicEnergyProject .context-menu .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.Card.PublicEnergyProject .detail-component {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    position: relative;
    height: 100%;

}

.Card.PublicEnergyProject .detail-container {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    margin: 5px;
}

.Card.PublicEnergyProject .detail-section {
    margin: 0 auto;
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 600px;
}

.Card.PublicEnergyProject .detail-section ul {
    margin: 2px;
}

.Card.PublicEnergyProject .detail-section .image {
    width: 100%;
    height: 180px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
}

.Card.PublicEnergyProject .detail-section .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Card.PublicEnergyProject .detail-section .row {
    margin-bottom: 5px;
    margin-top: 5px;
}

.Card.PublicEnergyProject .detail-section .row .title {
    text-align: center;
    margin-bottom: 10px;
}

.Card.PublicEnergyProject .detail-section .row .description {
    padding-top: 10px;
    padding-bottom: 10px;
}

.Card.PublicEnergyProject .detail-section .row span.label {
    font-weight: bold;
}

.Card.PublicEnergyProject .detail-section .row .download-link {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.Card.PublicEnergyProject .detail-section .row .download-link i {
    display: flex;
    font-size: 1.2em;
    padding: 5px;
}

.PublicEnergyProject .custom-panel-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 75);
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 0;
}

.PublicEnergyProject .custom-panel-loader .loader {
    color: white;
    display: flex;
}
