.FavoriteList {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    flex: 1 1;
}

.FavoriteList .list-container {
    position: relative;
    min-height: 100px;
}

.FavoriteList div.list-container:first-child {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 800px;
    flex-direction: column;
}