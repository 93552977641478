.SepRemote{
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.component-remote-controller{
    display: flex;
    flex-direction: column;
    z-index: 0;
    left: 0;
    bottom: 0;
}