.Resend{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 15px;
}

.Resend .resend-form{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
}

.Resend .heading{ text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Resend .image{
    max-width: 200px;
}

.Resend .column-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Resend .row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Resend .isSuccess-true{
    display: none;
}

.Resend .footer{
    text-align: center;
    padding-top: 20px;

}

.Resend .info{
    margin-top: 20px;
    margin-bottom: 20px;
}