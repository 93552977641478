.Card.SBBCard {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 290px;
    min-height: 240px;
}

.Card.SBBCard .controls {
    display: flex;
    justify-content: flex-end;
}

.Card.SBBCard .controls .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
}

.Card.SBBCard {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    background-color: #ffffff;
}

.Card.SBBCard:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.Card.SBBCard.selected {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    background-color: var(--gi_color_accent_color);
}

.Card.SBBCard .body {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}

.Card.SBBCard .body .overview {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}


.Card.SBBCard .overview h1 {
    text-align: center;
}

.Card.SBBCard .row {
    width: 100%;
    display: flex;
}

.Card.SBBCard .sub {
    margin-left: 20px;
}

.Card.SBBCard .settings .color {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c1 {
    background-color: #f4ff00;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c2 {
    background-color: #fff000;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c3 {
    background-color: #ffbe00;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c4 {
    background-color: #f4a500;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c5 {
    background-color: #ff6000;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c6 {
    background-color: #ff5b00;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c7 {
    background-color: #ff3b00;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c8 {
    background-color: #9c001c;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .settings .color .c9 {
    background-color: #7b0011;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: flex;
}

.Card.SBBCard .sbb-overview{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Card.SBBCard .body {

}
.Card.SBBCard .sbb-logo *{
    display: flex;
    flex: 1;
    height: 24px;
    margin-top: 12px;
}
.Card.SBBCard .sbb-logo-2 *{
    display: flex;
    flex: 1;
    height: 20px;
    margin: 0 auto;
}

.Card.SBBCard.SBBCard .body{
    margin: 10px;
}
