#dashboard-portal .ConstructionZoneSettings{
    max-width: 600px;
}

#dashboard-portal .ConstructionZoneSettings .settings-container .ui.multiple.selection.dropdown {
    margin: 0px auto;
    width: 100%;
}

#dashboard-portal .ConstructionZoneSettings .settings{
    width: 100%;
    max-width: 600px;
}

#dashboard-portal .ConstructionZoneSettings .dropdown{
    margin-left: 5px;
    margin-right: 5px;
}

#dashboard-portal .ConstructionZoneSettings .dropdown *{
    border-radius: 0px;
    border-color: var(--gi_color_primary_color)
}

#dashboard-portal .ConstructionZoneSettings .dropdown:hover,
#dashboard-portal .ConstructionZoneSettings .dropdown{
    border-radius: 0px;
    border-color: var(--gi_color_primary_color);
}

#dashboard-portal .ConstructionZoneSettings .multiple.selection.dropdown:first-child{

}

#dashboard-portal .ConstructionZoneSettings .button-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

#dashboard-portal .ConstructionZoneSettings .button-container button{
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

#dashboard-portal .ConstructionZoneSettings .datum-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

#dashboard-portal .ConstructionZoneSettings .menu.transition{
    position: absolute;
    top: 40px;
    bottom: 0px;
    min-height: 50px;
    height: 530%;
    max-height: 400px;
}

.ConstructionZoneSettings .selection .dropdown{
    width: 100%;
    margin: 0 auto;
}

.ConstructionZoneSettings .ui.form input {
    max-width: 300px;
}

#dashboard-portal .ConstructionZoneSettings .datum-wrapper,
#dashboard-portal .ConstructionZoneSettings .datum-wrapper input,
#dashboard-portal .ConstructionZoneSettings .datum-wrapper button{
    display: flex;
}
