.CookiePolicy {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; /* 2 because it needs to be higher than the installation propmpt*/
    height: 40vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.CookiePolicy * {
    display: flex;
    margin: 10px;
}

#root .CookiePolicy div.buttons button {
    color: var(--gi_color_dark_primary_color);
    margin: 5px;
}