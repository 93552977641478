.SolarElectricityCard .body .detail .roof
{
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    padding: 20px;
}
.SolarElectricityCard .body .detail .roof .item{
    display: flex;
    flex-wrap: wrap;
}
.SolarElectricityCard .body .detail .roof .subitem {
    display: flex;
    flex-wrap: wrap;
}

.SolarElectricityCard .body .settings{
    display: flex;
}

.SolarElectricityCard .detail .items *{
    margin: 0px;
}
.SolarElectricityCard .detail .items *{
    margin: 0px;
}

.SolarElectricityCard .items .item.operators{
    display: flex;
    flex-direction: column;
}
.SolarElectricityCard .items .item .operators{
    display: flex;
    flex-direction: column;
}

.SolarElectricityCard .settings form{
    margin: 0 auto;
}

.SolarElectricityCard .overview h1,
.SolarElectricityCard .overview h3
{
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
}
.SolarElectricityCard .overview .icon{
    margin: 0 auto;
}

.SolarElectricityCard .settings .field{
    display: flex;
    align-items: center;
}

.SolarElectricityCard .empty-selection{
    text-align: center;
}

.SolarElectricityCard .body .detail{
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
}

.SolarElectricityCard .body .detail h1,
.SolarElectricityCard .body .detail h2,
.SolarElectricityCard .body .detail h3,
.SolarElectricityCard .body .detail h4{
    text-align: left;
}

.SolarElectricityCard .link-like{
    color: var(--gi_color_accent_color);
}

