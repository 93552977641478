.remote-geoimpact-link{
    font-weight: bold;
    font-size: 1.2em;
    padding: 5px;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
}
.remote-geoimpact-link a{
    color: black;
}

.remote-map-attribution{
    font-weight: bold;
    font-size: 1.2em;
    padding: 5px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
}
.remote-map-attribution a{
    color: black;
}
