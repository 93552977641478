.Reset{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 15px;
}

.Reset .reset-form{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
    padding: 15px;
}

.Reset .heading{ text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Reset .image{
    max-width: 200px;
}

.Reset .column-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Reset .row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Reset .isSuccess-true{
    display: none;
}

.Reset .footer{
    text-align: center;
    padding-top: 20px;

}