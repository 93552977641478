.SEPMap .OverlayDashboard{
    z-index: 0;
    position: absolute;
    display: flex;
    flex: 1;
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--gi_color_light_transparent_primary_color);
    transition: top 1s;
    will-change: top;
    margin: 0;
}

.SEPMap .OverlayDashboard.high{
    top: 0;
}
.SEPMap .OverlayDashboard.mid{
    top: 48%;
}
.SEPMap .OverlayDashboard.low{
    top: 90%;
}

.SEPMap .OverlayDashboard .container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

.SEPMap .OverlayDashboard .handle {
    min-height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
}

.SEPMap .OverlayDashboard .ui.button.arrow{
    color: var(--gi_color_accent_color);
    background: transparent;
    font-size: 1.5em;
    padding: 0px 40px;
}

.SEPMap .OverlayDashboard .geoimpact-link{
    font-weight: bold;
    position: absolute;
    right: 15px;
}
.SEPMap .OverlayDashboard .geoimpact-link a, .SEPMap .OverlayDashboard .geoimpact-link a:hover{
    color: var(--gi_color_accent_color);
}

@media screen and (max-width: 800px) {
    .SEPMap .OverlayDashboard .ui.button.arrow{
        padding-left: 15px;
        padding-right: 15px;
    }
    .SEPMap .OverlayDashboard .geoimpact-link{
        font-weight: bold;
        position: absolute;
        right: 15px;
        font-size: 0.8em;
    }
    .SEPMap .OverlayDashboard .geoimpact-link a, .SEPMap .OverlayDashboard .geoimpact-link a:hover{
        color: var(--gi_color_accent_color);
    }
}

.SEPMap .map-attribution{
    position: absolute;
    top: -30px;
}

.SEPMap .map-attribution{
    font-weight: bold;
    padding: 5px;
    position: absolute;
    color: black;
}
.SEPMap .map-attribution a{
    color: black;
}
