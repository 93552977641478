.ConstructionZone{

}

.ConstructionZone .body{
    align-items: center;
    justify-content: center;
}

.ConstructionZone .body .overview{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ConstructionZone .body .overview h1{
    padding-left: 15px;
    padding-right: 15px;
}

.ConstructionZone .body .overview h3{
    text-align: center;
    align-items: center;
    justify-content: center;
}

.ConstructionZone .body .overview div i{
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 5rem;
}

.ConstructionZone .body .detail div{
    padding: 15px;
}
.ConstructionZone .body .detail{
    padding: 15px;
}

.ConstructionZone .overview *{
    margin: 0;
}

.ConstructionZoneSettings h4{
    text-align: center;
}
