html{
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
body{
    position: fixed;
    overflow: hidden;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}
#root, .App{
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

/* pwa banner */
#root .App .pwa-banner{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
}
#root .App .pwa-banner button{
    margin: 10px;
}

