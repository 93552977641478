.SEPMap .PublicPanelContainer-flex {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: fit-content;
}
.SEPMap .PublicPanelContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: fit-content;
    flex: 1 1;
    overflow: auto;
}

.SEPMap #overlay-dashboard-panels{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: 100%;
}
.SEPMap #overlay-dashboard-panels .Card{
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: fit-content;
    flex: 1 1;
    height: fit-content;
    overflow: auto;
}
