.events-container{
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.events-container-absolute{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.event-list{
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.event-list .ui.loader{
    display: flex;
}

.event-list .event{
    background-color: rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: fit-content;
}
.event{
    display: flex;
    flex-wrap: wrap;
}
.event-list .event .column.info{
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
}
.event-list .action-container.event-col{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.event-list .action-container.event-col > div{
    display: flex;
}
.event-list .event:hover {
    background-color: var(--gi_color_light_primary_color);
}

.event-list .event .column{
    display: flex;
}
.event-list .event .column.info{
    flex: 1;
}

.event-list .event .column.action {
    display: flex;
    min-width: 50px;
    align-items: center;
    justify-content: center;
}

.event-list .event .column.action button{
    background: none;
    display: block;
    padding: 10px;
}
.event-list .event .column.action button i{
    font-size: 1.5em;
}

.event-list .event .event-data{
    display: flex;
    flex: 1;
    position: relative;
}
.event-list .event .event-data .container{
    display: flex;
    flex: 1;
    flex-direction: row;
}
.event-list .event .event-data .container .column{
    display: flex;
    flex-direction: column;
}

.event-list .event .event-data .container .column.data h4{
    margin: 0;
}

.event-list .event .event-data .container .column.icon{
    display: flex;
    flex: 0.2;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.event-list .event .event-data .container .column.data{
    display: flex;
    flex: 1;
    margin: 5px;
    min-width: 140px;
    word-break: break-word;
}

.event-list .event .event-data .loader{
    display: flex;
}

.event-list .event .image{
    min-width: 40px;
    min-height: 40px;
    max-width: 150px;
    max-height: 150px;
    padding: 15px;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
}

.events-container-absolute .controls {
    display: flex;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
}
.events-container-absolute .controls .advanced{
    display: flex;
    flex: 1;
}
.events-container-absolute .controls.paginator .advanced{
    align-items: center;
    justify-content: center;
    margin: 5px;
}
.events-container-absolute .controls .advanced {
    flex-wrap: wrap;
}
.events-container-absolute .controls .advanced .column.filter{
    display: flex;
    flex: 1;
    margin: 5px;
}
.events-container-absolute .controls .advanced .column.delete {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

.events-container-absolute .controls .advanced .column.filter div,
.events-container-absolute .controls .advanced .column.filter input{
    display: flex;
    flex: 1;
    width: 100%;
}

.events-container-absolute .controls .advanced{

}

.events-container-absolute .controls .advanced .column.filter input,
.events-container-absolute .controls .advanced .column.filter button{
    margin: 0px;
}

.page-title{
    margin: 10px 0 10px 0;
    text-align: center;
}

/*PAGINATION*/
.favorites .pagination-container{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.favorites .ui.pagination.menu .active.item{
    background-color: var(--gi_color_light_primary_color);
}
.favorites .ui.pagination.menu .item{
    min-width: 0;
    padding: 10px;
}
