.Navbar {
    background-color: var(--gi_color_dark_primary_color);
    display: flex;
}

#root .Map .Navbar {
    background-color: var(--gi_color_dark_primary_color);
    z-index: 2;
}

.Navbar .navbar-container {
    display: flex;
    flex: 1;
}

.Navbar .navbar-container .element{
    display: flex;
}

.Navbar .navbar-container .input-wrapper {
    display: flex;
    flex: 1;
}