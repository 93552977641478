.FavoriteActionBar .actionbar-container {
    display: flex;
    flex: 1 1;
    max-width: 800px;
    align-items: center;
    margin-top: 5px;
}

.FavoriteActionBar, .FavoritesSort {
    display: flex;
    justify-content: center;
}

.FavoriteActionBar .actionbar-container i {
    margin: 8px;
    font-size: 1.3em;
    color: black;
}

.FavoriteActionBar .delete-component {
    position: relative;
}

.FavoriteActionBar .actionbar-container i:hover {
    color: var(--gi_color_accent_color);
}