.flexitem{
    display: flex;
}

.EnergyProject .custom-panel-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 75);
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: white;
    z-index: 0;
}

.EnergyProject .custom-panel-loader .loader {
    color: white;
    display: flex;
}

.EnergyProject .card-body {
    display: flex;
    flex: 1;
    position: relative;
}

.EnergyProject .context-menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
}

.EnergyProject .context-menu .menu-items-wrapper {
    display: flex;
    flex-direction: column;
}

.EnergyProject .context-menu .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.EnergyProject .card-body-portal:first-child {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
}

.EnergyProject .card-body-portal .menu-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.EnergyProject .card-body-portal .menu-container .edit-project button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.EnergyProject.in-portal {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
}

.EnergyProject.in-portal .card-body {
    overflow: auto;
}

.EnergyProject .card-body .overview-component {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.EnergyProject .card-body .overview-component * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.EnergyProject .card-body .overview-component .overview-container * {
    margin: 0 auto;
}

.EnergyProject .sub-component {
    display: flex;
    flex: 1;
    width: 100%;
}

.EnergyProject .edit-project {
    width: 100%;
}

.EnergyProject .edit-project .form-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
}

.EnergyProject .edit-project label {
    font-weight: bold;
    font-size: 1em;
    z-index: 0;
}

.EnergyProject .edit-project label.sub-label {
    font-weight: normal;
    font-size: 0.8em;
}

.EnergyProject .edit-project textarea {
    margin: 2px;
}

.EnergyProject .edit-project .form-container {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.EnergyProject .edit-project .form-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 250px;
    margin: 10px;
    padding: 10px;
    /*background: #eeeeee;*/
}
@media only screen and (min-width: 600px) {
    /*device is bigger than 600px*/
    .EnergyProject .edit-project .form-section {
        max-width: 320px;
    }

    .EnergyProject .edit-project .form-section.actions {
        min-width: 250px;
    }

    .EnergyProject .edit-project .block-description {
        max-width: 100%;
        width: fit-content;
        width: -moz-fit-content;
    }
    .EnergyProject .edit-project .block-description .description-form{
        margin: 10px;
        padding: 10px;
    }
    .EnergyProject .edit-project .block-description textarea{
        min-height: 300px;
        min-width: 250px;
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    /*device is smaller than 600px*/
    .EnergyProject .edit-project .form-section {
        width: 100%;
    }

    .EnergyProject .edit-project .block-description {
        width: 100%;
        max-width: 100%;
    }
    .EnergyProject .edit-project .block-description .description-form{
        margin: 10px;
        padding: 10px;
    }
    .EnergyProject .edit-project .block-description textarea{
        min-height: 100px;
        width: 100%;
        max-width: 100%;
    }
}

/*#root .EnergyProject .edit-project .form-section.actions .section-row button{
    margin-bottom: 10px;
}*/


.EnergyProject .edit-project .section-row {
    margin-top: 10px;
}

.EnergyProject .form-section .toggle-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
}

.EnergyProject .dropdown .visible.menu.transition {
    min-height: 160px;
    overflow-y: scroll;
}

.EnergyProject .coordinate-selector {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.EnergyProject .coordinate-selector i {
    padding: 0px;
    font-size: 1.5em;
}

.EnergyProject .coordinate-selector span {
    padding: 0px;
}

.EnergyProject .energy-info .input-group {
    display: flex;
    flex: 1;
    flex-direction: row;
    position: relative;
}

.EnergyProject .energy-info .input-group div input {
    display: flex;
    flex: 1;
    min-width: 20px;
}
.EnergyProject .energy-info .input-group div{
    flex: 1;
    min-width: 20px;
}

.EnergyProject .energy-info .item {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 5px;
    align-items: center;
    justify-content: space-between;
}

.EnergyProject .energy-info .item i {
    font-size: 1.5em;
}

/* all projects (detail) */
.EnergyProject .card .image {
    width: 100%;
    min-width: 210px;
    max-width: 600px;
    min-height: 210px;
    max-height: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 0 auto;
    display: flex;
}

.EnergyProject .card .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.EnergyProject .card-container-absolute {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    position: relative;
    height: 100%;
    min-height: fit-content;
    overflow: auto;
}

.EnergyProject .card-container {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    min-height: fit-content;
}

.EnergyProject .card-container .message {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 15px;
    text-align: left;
}

.EnergyProject .download-link {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.EnergyProject .download-link i {
    font-size: 1.5em;
}

.EnergyProject .card {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    max-width: 400px;
    width: 100%;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.EnergyProject .card-container .controls i{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
}
.EnergyProject .card-container .controls div:first-child{
    float: left;
    align-items: center;
    padding: 5px;
    width: 100%;
}

.EnergyProject .context-menu .controls button i {
    min-width: 18.8px;
}
.EnergyProject .controls div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EnergyProject .card-container .extra {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
}

/*SETTINGS*/
.EnergyProject .card-body .settings {
    width: 100%;
    display: flex;
}

.EnergyProject .card-body .settings .flex-wrapper {
    width: 100%;
}

.EnergyProject .card-body .form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.EnergyProject .card-body .settings label {
    font-weight: bold;
    font-size: 1em;
}

.EnergyProject .settings .section-row.button-contianer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

#root .EnergyProject .card-body .settings button {
    max-width: 300px;
}

#root .EnergyProject .form-section.actions .section-row button {
    margin-bottom: 10px;
}

.EnergyProject .card-body .settings .flex-wrapper .form-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 250px;
    max-width: 600px;
    margin: 10px;
    padding: 10px;
    /*background: #eeeeee;*/
}

.EnergyProject .card-body .settings .flex-wrapper .section-row {
    margin-bottom: 10px;
}

.EnergyProject .settings .form .inline-input {
    display: flex;
}

.EnergyProject .settings .form .inline-input > i {
    padding: 10px;
}

.EnergyProject .settings .form .inline-input {
    display: flex;
    align-items: center;
    justify-content: center;
}

.EnergyProject .settings .section-row i {
    font-size: 1.5em;
}

.EnergyProject .settings .form .inline-input.date div,
.EnergyProject .settings .form .inline-input.date input {
    display: flex;
    flex: 1;
}

.EnergyProject .card-body .settings .flex-wrapper .section-row.link {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
}
.EnergyProject .card-body .settings .flex-wrapper .section-row.link textarea{
    display: flex;
    flex: 1;
}


/* semantic ui override */
#root .ui.toggle.checkbox input:checked ~ .box:before,
#root .ui.toggle.checkbox input:checked ~ label:before {
    background-color: var(--gi_color_dark_primary_color) !important;
}

#root .ui.selection.dropdown .menu {
    box-shadow: none;
}

.ProjectCard.card.focused {
    background-color: rgba(14, 114, 181, 0.3);
}

