
.Feedback .container{
    display: flex;
    flex: 1 1;
    align-items: center;
    overflow: auto;
    height: fit-content;
}

.Feedback .container .form-container{
    max-width: 600px;
    margin: 0 auto;
    padding: 10px;
    max-height: 100%;
    overflow: auto;
}

.Feedback .container .field.centered h3{
    text-align: left;
}

.Feedback .container .field.submit-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Feedback .container .field.star{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Feedback .container .field.star div{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.Feedback .container .field.star span{
    margin-top: 5px;
    display: flex !important;
    flex: 1;
    justify-content: space-between;
}
.Feedback .container .field.star span span{
    color: gold;
    font-size: 1.1em;
}

.Feedback .form-container .thanks-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Feedback .form-container button{
    min-width: 180px;
}
