/*** DynamicPanels.js ***/


/*** DynamicPanel.js ***/
.dynamic-panel {
    background: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 290px;
    min-height: 240px;
    margin: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.dynamic-panel:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.error-boundary{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.error-boundary .message{
    max-height: 220px;
    overflow: auto;
    word-break: break-all;
}
