.BuildingInfo{

}

.BuildingInfo.detail{
    width: 100%;
}

.BuildingInfo .detail:first-child{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 100%;
}

.BuildingInfo .body{
    align-items: center;
    justify-content: center;
}

.BuildingInfo .body .overview{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.BuildingInfo .body .overview h1,
.BuildingInfo .body .overview h3
{
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}

.BuildingInfo .body .overview div i{
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 5rem;
}


.BuildingInfo .overview h1, .overview h3 {
    margin: 0 auto;
}

.BuildingInfo .detail h1,
.BuildingInfo .detail h2,
.BuildingInfo .detail h3,
.BuildingInfo .detail h4
{
    text-align: center;
}
.BuildingInfo .detail .rowcontainer div.row{
    padding: 0;
    display: flex;
    flex-direction: row;
}
.BuildingInfo .detail .rowcontainer div.row div:first-child{
    font-weight: bold;
}

.BuildingInfo .detail .rowcontainer .addresses{
    flex-direction: column
}

.BuildingInfo .detail .rowcontainer{
    margin-top: 15px;
}

.BuildingInfo .detail .rowcontainer div .edids,
.BuildingInfo .detail .rowcontainer div .ewids{
    flex-direction: column;
}

.BuildingInfo .detail .rowcontainer div .edids-item,
.BuildingInfo .detail .rowcontainer div .ewids-item{
    margin: 0;
}

.BuildingInfo .detail .rowcontainer div .ewids-container,
.BuildingInfo .detail .rowcontainer div .edids-container{
    flex-direction: column;
}

.BuildingInfo .detail .rowcontainer .bold{
    font-weight: bold;
}

.BuildingInfo .rowcontainer-wrapper {
    padding: 20px;
}

.BuildingInfo .edids h4 {
    text-align: left;
    margin: 0;
    margin-top: 15px;
}

#dashboard-portal .BuildingInfo .rowcontainer .edids-container {
    display: flex;
    flex-direction: column;
}

.BuildingInfo .empty-selection{
    text-align: center;
}

.BuildingInfo .detail .rowcontainer .minergie-container,
.BuildingInfo .detail .rowcontainer .minergie{
    display: flex;
    flex-direction: column;
}
.BuildingInfo .detail .rowcontainer .minergie div{
    padding: 0;
    margin: 0;
    font-weight: normal;
}
.BuildingInfo .detail .rowcontainer .minergie h4{
    margin-bottom: 0;
    margin-top: 8px;
    text-align: left;
}

.BuildingInfo .detail .rowcontainer .minergie .fat{
    font-weight: bold;
}

.BuildingInfo .minergie-container{
    margin-bottom: 10px;
}

#building-info-leaf-portal .address-point{
    color: white;
    background-color: var(--gi_color_accent_color);
    padding: 4px;
    font-weight: bold;
    font-size: large;
    opacity: 0.8;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;

    /*The CSS below stops users from being able to select text.*/
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

#building-info-leaf-portal .address-point.open{
    opacity: 1;
}

#building-info-leaf-portal {


}

.address-point-street.hidden{
    display: none;
}

.BuildingInfo .address-focus-row{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    overscroll-behavior: none;
}

.BuildingInfo .address-focus-row i{
    font-size: 1.8em;
}

#building-info-leaf-portal .BuildingInfo .event-bypass{
    pointer-events: none;
}


.custom-pin-address{
    opacity: 0.88;
}

.custom-pin-address span{
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    white-space:nowrap;
    background: #2185d0;
    padding: 5px;
    border-radius: 15px;
}

