.SEPMap .PanelContainer-flex {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    flex-wrap: wrap;
}

.SEPMap .PanelContainer-flex.invisible {
    display: none;
}

.SEPMap .PanelContainer #dashboard-portal {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    overflow: auto;
}
.SEPMap .PanelContainer #dashboard-portal.invisible {
    display: none;
}

.SEPMap .PanelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;
}

.SEPMap #overlay-dashboard-panels{
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    height: 100%;
}
.SEPMap #overlay-dashboard-panels .Card{
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: fit-content;
    flex: 1 1;
    height: fit-content;
    overflow: auto;
}

/*CARD*/
.Card {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 290px;
    min-height: 240px;
    background-color: white;
    margin: 5px;
}

.Card .controls{
    display: flex;
    justify-content: flex-end;
}

.Card .controls .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
}

.Card {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.Card:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.Card.selected {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    background-color: var(--gi_color_accent_color);
}

.Card .body {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}

.Card .body .overview {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}


.Card .overview h1{
    text-align: center;
}
