.WebPush{
    margin: 0 auto;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.WebPush .pusher .ui.basic.segment{
    padding: 0px;
}

.WebPush .content{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}

.WebPush .privacy-body{
    padding: 0px;
}

.WebPush .privacy-body .list
{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.WebPush .privacy-body h3
{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.WebPush .privacy-body
{
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    border: none;
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.WebPush .iframe-wrapper {
    width: 100%;
    height: 100%;
}
