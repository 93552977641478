.PanelConfig-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
}

.PanelConfig-container-absolute {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.PanelConfig-container-absolute .codemirror,
.PanelConfig-container-absolute .actions {
    display: flex;
    flex-direction: column;
}

.PanelConfig .section {
    margin: 5px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.18);
}

.PanelConfig #codemirror > div {
    z-index: 0;
}

.PanelConfig .config-item {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
}

.PanelConfig .config-item .row {
    display: flex;
    flex: 1;
}

.PanelConfig .editor-action-row {
    display: flex;
    align-items: center;
}

.PanelConfig .config-item:hover {
    background-color: var(--gi_color_light_primary_color);
}

.PanelConfig .config-item .row {
    align-items: center;
    display: flex;
    min-width: 100px;
    padding: 5px;
}

.PanelConfig .config-item .row.actions {
    min-width: 150px;
    flex-wrap: wrap;
    flex-direction: row;
}

.PanelConfig .config-item .row.status{
    word-break: break-all;
}

.PanelConfig .config-item .row.actions button {

}

.PanelConfig .resizable {
    border: 2px solid;
    width: 100%;
    resize: vertical;
    overflow: auto;
}

.PanelConfig .dynamic-height {
    /*background-color: red;*/
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.PanelConfig #codemirror,
.PanelConfig #codemirror .CodeMirror {
    height: 100%;
    max-height: 100%;
}
