.UsageSettings{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.UsageSettings .container{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

.UsageSettings .color-element{
    display: flex;
    align-items: center;
}

.UsageSettings.settings .container{
    margin: 0 auto;
    width: fit-content;
    width: -moz-fit-content;
}
