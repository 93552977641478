.FavoriteFilter, .alert-wrapper {
    display: flex;
    justify-content: center;
}

.FavoriteFilter .filter-container {
    display: flex;
    flex: 1 1;
    max-width: 800px;
    align-items: center;
    margin-top: 5px;
}

.FavoriteFilter .filter-container div.input {
    flex: 1;
    max-width: 400px;
}

.FavoriteFilter i {
    font-size: 1.3em;
    color: black;
}