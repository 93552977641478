.Recover{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 15px;
}

.Recover .recover-form{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
    padding: 15px;
}

.Recover .heading{ text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Recover .image{
    max-width: 200px;
}

.Recover .column-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Recover .row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.Recover .footer{
    text-align: center;
    padding-top: 20px;

}

.Recover .recovery{
    margin-top: 20px;
    margin-bottom: 20px;
}

