.LanguageSwitch {
     max-width: 600px;
     margin: 0 auto;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    color: var(--gi_color_primary_text);
 }
.HyperMenu .language-switch *.button-container{
    display: flex;
    flex-direction: row;
}

.HyperMenu .language-switch *.button-container button{
    background: none;
    border: none;
    color: var(--gi_color_primary_text);
    outline: none;
    cursor: pointer;
}

.HyperMenu .language-switch *.button-container button.selected{
    background: var(--gi_color_dark_primary_color);
    border: none;
    color: var(--gi_color_accent_color);
}
