.Usage {
     display: flex;
     flex: 1;
     flex-direction: column;
     min-width: 300px;
     min-height: 240px;
 }

.Usage .controls{
    display: flex;
    justify-content: flex-end;
}

.Usage .controls .title {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
}

.Usage {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #ffffff;
}
.Usage:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.Usage.selected {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    background-color: var(--gi_color_accent_color);
}

.Usage .body {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
}

.Usage .body .overview {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.Usage .overview h1, h3{
    text-align: center;
}

.Usage .overview *{
    margin: 0px;
    display: flex;
    flex-direction: column;
}