.HyperMenu{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.HyperMenu > div.menu{
    position: fixed;
    background-color: var(--gi_color_dark_primary_color);
    bottom: 0;
    z-index: 2; /*2 because the map overlay is 1*/
}

.HyperMenu .menu-visible{
    min-width: 320px;
    max-width: calc(30vw);
}
.HyperMenu .menu-invisible{
    min-width: 320px;
    max-width: calc(30vw);
}

.HyperMenu .menu-content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.HyperMenu .menu-content-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
}

.HyperMenu .item-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
}
.HyperMenu .scrollable-items{
    overflow: auto;
}

.HyperMenu .item button{
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.HyperMenu .item.profile{
    margin-bottom: 10px;
}
.HyperMenu .item.profile button h3{
    margin: 0 auto;
}
.HyperMenu .item.profile button div{
    font-weight: normal;
}
.HyperMenu .item.profile button{
    flex-direction: column;
}

.HyperMenu .item.centered-all {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.HyperMenu .item.centered-all *{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.HyperMenu .item .version .image{
    max-width: 100px;
   /* -webkit-filter: brightness(0%);
    filter: brightness(0%);*/
    opacity: 1;
}

