.RoofDetail{
    overflow: auto;
}
.RoofDetail .body{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.RoofDetail .body .items .item{
    display: flex;
}

.RoofDetail .body .items .item h4{
    margin: 0;
}
