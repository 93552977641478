/*https://www.materialpalette.com/light-green/light-blue*/

:root {
    --gi_color_dark_primary_color: #689F38;
    --gi_color_light_primary_color: #DCEDC8;
    --gi_color_light_transparent_primary_color: rgba(220,237,200, 0.76);
    --gi_color_primary_color: #8BC34A;
    --gi_color_text_icon: #212121;
    --gi_color_accent_color: #0e72b5;
    --gi_color_accent_transparent_color: rgba(14, 114, 181, 0.76);
    --gi_color_primary_text: #212121;
    --gi_color_secondary_text: #757575;
    --gi_color_tertiary_text: #FFFFFF;
    --gi_color_alt_text: #FFFFFF;
    --gi_color_divider: #BDBDBD;

    --gi_color_primary_text_light: #FFFFFF;
    --gi_color_secondary_text_light: #d2d2d2;

    --gi_color_red: #d80000;
    --gi_oragne: #ff6f00;
}

#root button,
#root button i,
#root div.ui.label.label{
    border-radius: 0;
    margin: 0;
}

#root .ui.input input {
    color: var(--gi_color_text_icon);
    border-radius: 0;
}

#root .ui.form input {
    border-radius: 0;
}

#root .ui.input i {
    color: var(--gi_color_primary_text);
}

#root .ui.primary.button {
    background-color: var(--gi_color_dark_primary_color);
}

#root .ui.red.primary.button{
    background-color: var(--gi_color_red);
}

#root .ui.primary.button:hover {
    background-color: var(--gi_color_accent_color);
    color: var(--gi_color_tertiary_text);
}
#root .ui.primary.button {
    background-color: var(--gi_color_dark_primary_color);
    color: var(--gi_color_tertiary_text);
}

#root .ui.primary.button.active {
    background-color: var(--gi_color_primary_color);
    color: var(--gi_color_tertiary_text);
}

#root h1,
#root h2,
#root h3,
#root h4,
#root h5,
#root div,
#root p,
#root button,
#root input,
#root a {
    font-family: "Open Sans";
}

#root a:hover{
    text-decoration: underline;
}

#root select,
#root option
{
    font-size: 16px;
    padding: 4px;
    border-radius: 0;
    margin: 0;
    outline: none;
}

/*semantic ui overrides*/
#root .ui.loader{
    display: flex;
}
#root .ui.dropdown,
#root .ui.dropdown div{
    border-radius: 0px;
}
#root i.dropdown.icon{
    padding: 10px;
    font-size: 1.0em;
}

.ui.active.modal,
.ui.active.modal *,
div.ui.selection.dropdown
{
    border-radius: 0px;
}
div.ui.pagination.menu{
    margin: 5px;
    position: relative;
    border-radius: 0px;
    box-shadow: none;
}
div.ui.search>.results{
    border-radius: 0;
}

div:focus {
    outline: none;
}

/*SCROLLBAR*/
html::-webkit-scrollbar,
body::-webkit-scrollbar,
#root::-webkit-scrollbar,
.App::-webkit-scrollbar,
.SidebarFrame .sidebar *::-webkit-scrollbar {
    display: none;
}

iframe{
    border: 0px;
}

html,
body {
    overscroll-behavior-y: contain;
}
