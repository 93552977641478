.Elcom .overview{
    flex-direction: column;
}
.Elcom .overview h1{
    margin: 0;
}

.Elcom .settings {
    text-align: center;
}

.Elcom .detail {
    max-width: 600px;
    width: 100%;
}

.Elcom .body {
    align-items: center;
}

.Elcom .detail .table h3{
    margin-top: 30px;
    text-align: center;
}

.Elcom .Detail .section{
    margin-top: 0px;
    padding: 5px;
}

.Elcom .Detail .section h3,
.Elcom .Detail .section h4,
.Elcom .Detail .section h2
{
    margin: 0px;
}

.Elcom .Detail .section h4{
    margin-top: 5px;
}

.Elcom .Detail .accordion__item{
    /*background-color: #ececec;*/
    padding: 15px;
}

.Elcom .Detail .accordion__item .description{
    padding-top: 15px;
    padding-bottom: 15px;
}
.Elcom .Detail .accordion__body{
    background-color: #ffffff;
    padding: 0px;
}

.Elcom .Detail .accordion-title {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0px;
}

#root .Elcom .Detail .accordion-title button{
    margin-left: 15px;
}

#root .Elcom .Detail .description{
    padding-top: 5px;
    padding-bottom: 5px;
}


.Elcom .empty-selection{
    text-align: center;
}

.Elcom .Detail .detail.error{
    display: flex;
    align-items: center;
    justify-content: center;
}
