.EditProfile{
    display: flex;
    flex: 1;
    align-items: center;
    overflow: auto;
    padding: 15px;
}

.EditProfile .UserInfoDisplay{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}
.EditProfile .edit-form{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
    padding: 15px;
    height: 100%;
}

.EditProfile .info-email,
.EditProfile .info-buy,
.EditProfile .info-role
{
    margin-top: 25px;
}

.EditProfile .email-notification-settings
{
    margin-bottom: 25px;
}

.EditProfile .heading{ text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.EditProfile .image{
    max-width: 200px;
}

.EditProfile .column-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.EditProfile .row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.EditProfile .footer{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.EditProfile .account-delete{
    margin-top: 20px;
    margin-bottom: 20px;
}

.EditProfile .big-button{
    min-width: 300px;
}

.EditProfile .client-role{
    margin-top: 8px;
}

.EditProfile .logo-container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.EditProfile img.logo{
    max-width: 200px;
    max-height: 100px;
    margin: 0 auto;
}
.EditProfile .notification-switch {
    display: flex;
    justify-content: space-between;
}
.EditProfile .notification-switch p {
    margin-top: auto;
    margin-bottom: auto;
}
.EditProfile .event-notification {
    margin-top: 15px;
    margin-bottom: 15px;
}
.EditProfile .event-notification .alert-root {
    width: 100%;
}