.SignUp{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 15px;
}

.SignUp .signup-form{
    min-width: 300px;
    max-width: 600px;
    width: 100%;
}

.SignUp .heading{ text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SignUp .image{
    max-width: 200px;
}

.SignUp .column-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SignUp .row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.SignUp .isSuccess-true{
    display: none;
}

.SignUp .footer{
    text-align: center;
    padding-top: 20px;

}

.SignUp .resend-button button{
    min-width: 300px;
}
