.SuspenceLoader{
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SuspenceLoader *{
    display: flex;
    align-items: center;
    justify-content: center;
}

.SuspenceLoader .logo{
    max-width: 250px;
}