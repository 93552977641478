.ConstructionZone.Card .body{
    justify-content: flex-start;
}

.ConstructionZoneDetail .construction-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.ConstructionZoneDetail .construction-heading .ui.icon.button{
    background: transparent;
    color: black;
}

.ConstructionZoneDetail .title{
    font-weight: bold;
    font-size: 1.4em;
}

.ConstructionZoneDetail .line-title{
    font-weight: bold;
}

.ConstructionZoneDetail .logo-container{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ConstructionZoneDetail .construction{
    margin-top: 30px;
}

.ConstructionZone .body .detail div{
    padding: 0px;
}

.ConstructionZone.ConstructionZoneDetail .detail{
    min-width: 600px;
}