.FavoritePagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: fit-content;
}

.FavoritePagination .container {
    display: flex;
    flex: 1;
    position: relative;
}

.FavoritePagination .container div:first-child {
    position: relative;
}