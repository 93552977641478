.Navbar {
    display: flex;
    height: fit-content;
    min-height: fit-content;
}

.Favorites {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1;
}

.FavoritesSort {
    margin-top: 5px;
}

.FavoritesSort .favorites-sorting-container,
.alert-container {
    display: flex;
    flex: 1 1;
    max-width: 800px;
    align-items: center;
    margin-top: 5px;
}

.Favorites .message-no-favorites {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content .dropdown.region,
.content .dropdown.address{
    margin-top: 15px;
}
